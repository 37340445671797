import React from "react";
import '../../styles/rate-styles.scss';
import {MainNavbar} from "../banners/MainNavbar";

export const Rates = () => {
    return (
        <div>
            <section className="nav-bar-main">
                <MainNavbar src={require('../../assets/logos/ppt-mini-logo.png')}/>
            </section>

            <section>
                <div className="container rates-page">
                    <div className="table-container-outer">
                        <div className="table-container">
                            <table style={{marginTop: '40px'}}>
                                <tbody>
                                <tr>
                                    <th className="row-title" colSpan={16}>FROM PARIS ONE WAY TRIP RATES</th>
                                </tr>
                                <tr style={{height: '40px'}}>
                                    <th style={{width: '100px'}}
                                        className="style02 align-center table-coner-round-tl">From
                                    </th>
                                    <th style={{width: '135px'}} className="style02 align-center">Destination</th>
                                    <th className="style02 align-center">1-3 P.</th>
                                    <th className="style02 align-center">4 P.</th>
                                    <th className="style02 align-center">5 P.</th>
                                    <th className="style02 align-center">6 P.</th>
                                    <th className="style02 align-center">7 P.</th>
                                    <th className="style02 align-center">8 P.</th>
                                    <th className="style02 align-center">9 P.</th>
                                    <th className="style02 align-center">10 P.</th>
                                    <th className="style02 align-center">11 P.</th>
                                    <th className="style02 align-center">12 P.</th>
                                    <th className="style02 align-center">13 P.</th>
                                    <th className="style02 align-center">14 P.</th>
                                    <th className="style02 align-center">15 P.</th>
                                    <th className="style02 align-center table-coner-round-tr">16 P.</th>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 230</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 230</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>LA DEFENSE</th>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE SAINT-LAZARE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 50</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <td className="style03" align="center">
                                        <span>€ 50</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 50</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE MONTPARNASSE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 50</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02 table-coner-round-bl"
                                        style={{fontSize: '13px'}}>
                                        PARIS
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CHATEAU DE
                                        VERSAILLES
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 110</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 195</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 205</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03 table-coner-round-br" align="center">
                                        <span>€ 220</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="table-container-outer">
                        <div className="table-container">
                            <table style={{}}>
                                <tbody>
                                <tr>
                                    <th className="row-title" colSpan={16}>FROM PARIS ROUND TRIP RATES</th>
                                </tr>
                                <tr style={{height: '40px'}}>
                                    <th style={{width: '100px'}}
                                        className="style02 align-center table-coner-round-tl">From
                                    </th>
                                    <th style={{width: '135px'}} className="style02 align-center">Destination</th>
                                    <th className="style02 align-center">1-3 P.</th>
                                    <th className="style02 align-center">4 P.</th>
                                    <th className="style02 align-center">5 P.</th>
                                    <th className="style02 align-center">6 P.</th>
                                    <th className="style02 align-center">7 P.</th>
                                    <th className="style02 align-center">8 P.</th>
                                    <th className="style02 align-center">9 P.</th>
                                    <th className="style02 align-center">10 P.</th>
                                    <th className="style02 align-center">11 P.</th>
                                    <th className="style02 align-center">12 P.</th>
                                    <th className="style02 align-center">13 P.</th>
                                    <th className="style02 align-center">14 P.</th>
                                    <th className="style02 align-center">15 P.</th>
                                    <th className="style02 align-center table-coner-round-tr">16 P.</th>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>LA DEFENSE</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 230</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE SAINT-LAZARE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DAUSTERLITZ
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE MONTPARNASSE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>PARIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                </tr>
                                <tr>
                                    <th className="style02 table-coner-round-bl"
                                        style={{fontSize: '13px'}}>PARIS
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CHATEAU DE
                                        VERSAILLES
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03 table-coner-round-br" align="center">
                                        <span>€ 380</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="table-container-outer">
                        <div className="table-container">
                            <table style={{}}>
                                <tbody>
                                <tr>
                                    <th className="row-title" colSpan={16}>FROM CDG ONE WAY TRIP RATES</th>
                                </tr>
                                <tr style={{height: '40px'}}>
                                    <th style={{width: '100px'}}
                                        className="style02 align-center table-coner-round-tl">From
                                    </th>
                                    <th style={{width: '135px'}} className="style02 align-center">Destination</th>
                                    <th className="style02 align-center">1-3 P.</th>
                                    <th className="style02 align-center">4 P.</th>
                                    <th className="style02 align-center">5 P.</th>
                                    <th className="style02 align-center">6 P.</th>
                                    <th className="style02 align-center">7 P.</th>
                                    <th className="style02 align-center">8 P.</th>
                                    <th className="style02 align-center">9 P.</th>
                                    <th className="style02 align-center">10 P.</th>
                                    <th className="style02 align-center">11 P.</th>
                                    <th className="style02 align-center">12 P.</th>
                                    <th className="style02 align-center">13 P.</th>
                                    <th className="style02 align-center">14 P.</th>
                                    <th className="style02 align-center">15 P.</th>
                                    <th className="style02 align-center table-coner-round-tr">16 P.</th>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 110</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 115</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 195</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 230</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 230</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>LA DEFENSE</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 109</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 175</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE SAINT-LAZARE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE MONTPARNASSE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 125</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CHATEAU DE
                                        VERSAILLES
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 110</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 115</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 125</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 230</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 125</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 135</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 175</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02 table-coner-round-bl"
                                        style={{fontSize: '13px'}}>CDG
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CHATEAU DE
                                        VERSAILLES
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 110</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 115</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 195</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 230</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03 table-coner-round-br" align="center">
                                        <span>€ 250</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="table-container-outer">
                        <div className="table-container">
                            <table style={{}}>
                                <tbody>
                                <tr>
                                    <th className="row-title" colSpan={16}>FROM CDG ROUND TRIP RATES</th>
                                </tr>
                                <tr style={{height: '40px'}}>
                                    <th style={{width: '100px'}}
                                        className="style02 align-center table-coner-round-tl">From
                                    </th>
                                    <th style={{width: '135px'}} className="style02 align-center">Destination</th>
                                    <th className="style02 align-center">1-3 P.</th>
                                    <th className="style02 align-center">4 P.</th>
                                    <th className="style02 align-center">5 P.</th>
                                    <th className="style02 align-center">6 P.</th>
                                    <th className="style02 align-center">7 P.</th>
                                    <th className="style02 align-center">8 P.</th>
                                    <th className="style02 align-center">9 P.</th>
                                    <th className="style02 align-center">10 P.</th>
                                    <th className="style02 align-center">11 P.</th>
                                    <th className="style02 align-center">12 P.</th>
                                    <th className="style02 align-center">13 P.</th>
                                    <th className="style02 align-center">14 P.</th>
                                    <th className="style02 align-center">15 P.</th>
                                    <th className="style02 align-center table-coner-round-tr">16 P.</th>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 195</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 230</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 410</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 410</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>LA DEFENSE</th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 195</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE SAINT-LAZARE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>CDG</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02 table-coner-round-bl" style={{fontSize: '13px'}}>CDG
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE MONTPARNASSE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03 table-coner-round-br" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="table-container-outer">
                        <div className="table-container">
                            <table style={{marginTop: '40px'}}>
                                <tbody>
                                <tr>
                                    <th className="row-title" colSpan={16}>FROM DISNEYLAND ONE WAY TRIP RATES</th>
                                </tr>
                                <tr style={{height: '40px'}}>
                                    <th style={{width: '100px'}}
                                        className="style02 align-center table-coner-round-tl">From
                                    </th>
                                    <th style={{width: '135px'}} className="style02 align-center">Destination</th>
                                    <th className="style02 align-center">1-3 P.</th>
                                    <th className="style02 align-center">4 P.</th>
                                    <th className="style02 align-center">5 P.</th>
                                    <th className="style02 align-center">6 P.</th>
                                    <th className="style02 align-center">7 P.</th>
                                    <th className="style02 align-center">8 P.</th>
                                    <th className="style02 align-center">9 P.</th>
                                    <th className="style02 align-center">10 P.</th>
                                    <th className="style02 align-center">11 P.</th>
                                    <th className="style02 align-center">12 P.</th>
                                    <th className="style02 align-center">13 P.</th>
                                    <th className="style02 align-center">14 P.</th>
                                    <th className="style02 align-center">15 P.</th>
                                    <th className="style02 align-center table-coner-round-tr">16 P.</th>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>DISNEYLAND
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>DISNEYLAND
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>DISNEYLAND
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 175</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 195</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 195</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>DISNEYLAND
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>DISNEYLAND
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>DISNEYLAND
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>DISNEYLAND
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE SAINT-LAZARE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>DISNEYLAND
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>DISNEYLAND
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>DISNEYLAND
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE MONTPARNASSE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>DISNEYLAND
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 175</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02 table-coner-round-bl"
                                        style={{fontSize: '13px'}}>DISNEYLAND
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CHATEAU DE
                                        VERSAILLES
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03 table-coner-round-br" align="center">
                                        <span>€ 290</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="table-container-outer">
                        <div className="table-container">
                            <table style={{}}>
                                <tbody>
                                <tr>
                                    <th className="row-title" colSpan={16}>FROM DISNEYLAND ROUND TRIP RATES</th>
                                </tr>
                                <tr style={{height: '40px'}}>
                                    <th style={{width: '100px'}}
                                        className="style02 align-center table-coner-round-tl">From
                                    </th>
                                    <th style={{width: '135px'}} className="style02 align-center">Destination</th>
                                    <th className="style02 align-center">1-3 P.</th>
                                    <th className="style02 align-center">4 P.</th>
                                    <th className="style02 align-center">5 P.</th>
                                    <th className="style02 align-center">6 P.</th>
                                    <th className="style02 align-center">7 P.</th>
                                    <th className="style02 align-center">8 P.</th>
                                    <th className="style02 align-center">9 P.</th>
                                    <th className="style02 align-center">10 P.</th>
                                    <th className="style02 align-center">11 P.</th>
                                    <th className="style02 align-center">12 P.</th>
                                    <th className="style02 align-center">13 P.</th>
                                    <th className="style02 align-center">14 P.</th>
                                    <th className="style02 align-center">15 P.</th>
                                    <th className="style02 align-center table-coner-round-tr">16 P.</th>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE SAINT-LAZARE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE MONTPARNASSE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 580</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 610</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 630</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 650</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 660</span></td>
                                </tr>
                                <tr>
                                    <th className="style02 table-coner-round-bl"
                                        style={{fontSize: '13px'}}>DISNEYLAND
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CHATEAU DE
                                        VERSAILLES
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 410</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 420</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 450</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 460</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 470</span></td>
                                    <td className="style03 table-coner-round-br" align="center">
                                        <span>€ 480</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="table-container-outer">
                        <div className="table-container">
                            <table style={{}}>
                                <tbody>
                                <tr>
                                    <th className="row-title" colSpan={16}>FROM ORLY ONE WAY TRIP RATES</th>
                                </tr>
                                <tr style={{height: '40px'}}>
                                    <th style={{width: '100px'}}
                                        className="style02 align-center table-coner-round-tl">From
                                    </th>
                                    <th style={{width: '135px'}} className="style02 align-center">Destination</th>
                                    <th className="style02 align-center">1-3 P.</th>
                                    <th className="style02 align-center">4 P.</th>
                                    <th className="style02 align-center">5 P.</th>
                                    <th className="style02 align-center">6 P.</th>
                                    <th className="style02 align-center">7 P.</th>
                                    <th className="style02 align-center">8 P.</th>
                                    <th className="style02 align-center">9 P.</th>
                                    <th className="style02 align-center">10 P.</th>
                                    <th className="style02 align-center">11 P.</th>
                                    <th className="style02 align-center">12 P.</th>
                                    <th className="style02 align-center">13 P.</th>
                                    <th className="style02 align-center">14 P.</th>
                                    <th className="style02 align-center">15 P.</th>
                                    <th className="style02 align-center table-coner-round-tr">16 P.</th>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 175</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 195</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 195</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 110</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 115</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 230</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 230</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>LA DEFENSE</th>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE SAINT-LAZARE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 125</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 135</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE MONTPARNASSE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02 table-coner-round-bl"
                                        style={{fontSize: '13px'}}>ORLY
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CHATEAU DE
                                        VERSAILLES
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 110</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 110</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03 table-coner-round-br" align="center">
                                        <span>€ 220</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="table-container-outer">
                        <div className="table-container">
                            <table style={{}}>
                                <tbody>
                                <tr>
                                    <th className="row-title" colSpan={16}>FROM ORLY ROUND TRIP RATES</th>
                                </tr>
                                <tr style={{height: '40px'}}>
                                    <th style={{width: '100px'}}
                                        className="style02 align-center table-coner-round-tl">From
                                    </th>
                                    <th style={{width: '135px'}} className="style02 align-center">Destination</th>
                                    <th className="style02 align-center">1-3 P.</th>
                                    <th className="style02 align-center">4 P.</th>
                                    <th className="style02 align-center">5 P.</th>
                                    <th className="style02 align-center">6 P.</th>
                                    <th className="style02 align-center">7 P.</th>
                                    <th className="style02 align-center">8 P.</th>
                                    <th className="style02 align-center">9 P.</th>
                                    <th className="style02 align-center">10 P.</th>
                                    <th className="style02 align-center">11 P.</th>
                                    <th className="style02 align-center">12 P.</th>
                                    <th className="style02 align-center">13 P.</th>
                                    <th className="style02 align-center">14 P.</th>
                                    <th className="style02 align-center">15 P.</th>
                                    <th className="style02 align-center table-coner-round-tr">16 P.</th>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 295</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 295</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 195</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 410</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 420</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 430</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 440</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 450</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>LA DEFENSE</th>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE SAINT-LAZARE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE MONTPARNASSE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>ORLY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                </tr>
                                <tr>
                                    <th className="style02 table-coner-round-bl" style={{fontSize: '13px'}}>ORLY
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CHATEAU DE
                                        VERSAILLES
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 305</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03 table-coner-round-br" align="center">
                                        <span>€ 350</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="table-container-outer">
                        <div className="table-container">
                            <table style={{}}>
                                <tbody>
                                <tr>
                                    <th className="row-title" colSpan={16}>FROM BEAUVAIS ONE WAY TRIP RATES</th>
                                </tr>
                                <tr style={{height: '40px'}}>
                                    <th style={{width: '100px'}}
                                        className="style02 align-center table-coner-round-tl">From
                                    </th>
                                    <th style={{width: '135px'}} className="style02 align-center">Destination</th>
                                    <th className="style02 align-center">1-3 P.</th>
                                    <th className="style02 align-center">4 P.</th>
                                    <th className="style02 align-center">5 P.</th>
                                    <th className="style02 align-center">6 P.</th>
                                    <th className="style02 align-center">7 P.</th>
                                    <th className="style02 align-center">8 P.</th>
                                    <th className="style02 align-center">9 P.</th>
                                    <th className="style02 align-center">10 P.</th>
                                    <th className="style02 align-center">11 P.</th>
                                    <th className="style02 align-center">12 P.</th>
                                    <th className="style02 align-center">13 P.</th>
                                    <th className="style02 align-center">14 P.</th>
                                    <th className="style02 align-center">15 P.</th>
                                    <th className="style02 align-center table-coner-round-tr">16 P.</th>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 175</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>LA DEFENSE</th>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 135</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE SAINT-LAZARE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE MONTPARNASSE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th align="left" className="style02 table-coner-round-bl"
                                        style={{fontSize: '13px'}}>BEAUVAIS
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CHATEAU DE
                                        VERSAILLES
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 175</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03 table-coner-round-br" align="center">
                                        <span>€ 390</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="table-container-outer">
                        <div className="table-container">
                            <table style={{}}>
                                <tbody>
                                <tr>
                                    <th className="row-title" colSpan={16}>FROM BEAUVAIS ROUND TRIP RATES</th>
                                </tr>
                                <tr style={{height: '40px'}}>
                                    <th style={{width: '100px'}}
                                        className="style02 align-center table-coner-round-tl">From
                                    </th>
                                    <th style={{width: '135px'}} className="style02 align-center">Destination</th>
                                    <th className="style02 align-center">1-3 P.</th>
                                    <th className="style02 align-center">4 P.</th>
                                    <th className="style02 align-center">5 P.</th>
                                    <th className="style02 align-center">6 P.</th>
                                    <th className="style02 align-center">7 P.</th>
                                    <th className="style02 align-center">8 P.</th>
                                    <th className="style02 align-center">9 P.</th>
                                    <th className="style02 align-center">10 P.</th>
                                    <th className="style02 align-center">11 P.</th>
                                    <th className="style02 align-center">12 P.</th>
                                    <th className="style02 align-center">13 P.</th>
                                    <th className="style02 align-center">14 P.</th>
                                    <th className="style02 align-center">15 P.</th>
                                    <th className="style02 align-center table-coner-round-tr">16 P.</th>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 610</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 610</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 500</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 560</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 560</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 580</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 580</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 590</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>LA DEFENSE</th>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 560</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 580</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 560</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 580</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 560</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 580</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 560</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 580</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE SAINT-LAZARE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 560</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 580</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 560</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 580</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 560</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 580</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>BEAUVAIS</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>GARE MONTPARNASSE
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 560</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 580</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 600</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 620</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 640</span></td>
                                </tr>
                                <tr>
                                    <th className="style02 table-coner-round-bl"
                                        style={{fontSize: '13px'}}>BEAUVAIS
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CHATEAU DE
                                        VERSAILLES
                                    </th>
                                    <td className="style03" align="center">
                                        <span>€ 450</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 460</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 470</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 480</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 490</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 500</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 900</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 910</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 920</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 930</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 940</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 950</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 960</span></td>
                                    <td className="style03 table-coner-round-br" align="center">
                                        <span>€ 970</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="table-container-outer">
                        <div className="table-container">
                            <table style={{}}>
                                <tbody>
                                <tr>
                                    <th className="row-title" colSpan={16}>FROM PARIS TRAIN STATIONS RATES ONEWAY</th>
                                </tr>
                                <tr style={{height: '40px'}}>
                                    <th style={{width: '100px'}}
                                        className="style02 align-center table-coner-round-tl">From
                                    </th>
                                    <th style={{width: '135px'}} className="style02 align-center">Destination</th>
                                    <th className="style02 align-center">1-3 P.</th>
                                    <th className="style02 align-center">4 P.</th>
                                    <th className="style02 align-center">5 P.</th>
                                    <th className="style02 align-center">6 P.</th>
                                    <th className="style02 align-center">7 P.</th>
                                    <th className="style02 align-center">8 P.</th>
                                    <th className="style02 align-center">9 P.</th>
                                    <th className="style02 align-center">10 P.</th>
                                    <th className="style02 align-center">11 P.</th>
                                    <th className="style02 align-center">12 P.</th>
                                    <th className="style02 align-center">13 P.</th>
                                    <th className="style02 align-center">14 P.</th>
                                    <th className="style02 align-center">15 P.</th>
                                    <th className="style02 align-center table-coner-round-tr">16 P.</th>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE SAINT-LAZARE</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 50</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE SAINT-LAZARE</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE SAINT-LAZARE</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE SAINT-LAZARE</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 50</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 125</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 135</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 50</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE MONTPARNASSE</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 50</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE MONTPARNASSE</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE MONTPARNASSE</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 90</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 95</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 100</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 105</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                </tr>
                                <tr>
                                    <th className="style02 table-coner-round-bl" style={{fontSize: '13px'}}>GARE
                                        MONTPARNASSE
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 60</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 65</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 70</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 75</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 80</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 85</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 145</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 155</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 165</span></td>
                                    <td className="style03 table-coner-round-br" align="center">
                                        <span>€ 170</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="table-container-outer">
                        <div className="table-container">
                            <table style={{}}>
                                <tbody>
                                <tr>
                                    <th className="row-title" colSpan={16}>FROM PARIS TRAIN STATIONS RATES ROUND TRIP
                                    </th>
                                </tr>
                                <tr style={{height: '40px'}}>
                                    <th style={{width: '100px'}}
                                        className="style02 align-center table-coner-round-tl">From
                                    </th>
                                    <th style={{width: '135px'}} className="style02 align-center">Destination</th>
                                    <th className="style02 align-center">1-3 P.</th>
                                    <th className="style02 align-center">4 P.</th>
                                    <th className="style02 align-center">5 P.</th>
                                    <th className="style02 align-center">6 P.</th>
                                    <th className="style02 align-center">7 P.</th>
                                    <th className="style02 align-center">8 P.</th>
                                    <th className="style02 align-center">9 P.</th>
                                    <th className="style02 align-center">10 P.</th>
                                    <th className="style02 align-center">11 P.</th>
                                    <th className="style02 align-center">12 P.</th>
                                    <th className="style02 align-center">13 P.</th>
                                    <th className="style02 align-center">14 P.</th>
                                    <th className="style02 align-center">15 P.</th>
                                    <th className="style02 align-center table-coner-round-tr">16 P.</th>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 230</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU NORD</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DU LYON</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 210</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 220</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE LEST</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE SAINT-LAZARE</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE SAINT-LAZARE</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE SAINT-LAZARE</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 200</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE SAINT-LAZARE</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE BERCY</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 380</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 390</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 400</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE DE AUSTERLITZ</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE MONTPARNASSE</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>PARIS</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE MONTPARNASSE</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>CDG</th>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" style={{fontSize: '13px'}}>GARE MONTPARNASSE</th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>DISNEYLAND</th>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 180</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 185</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 190</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 310</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 320</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 330</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 340</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 350</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 360</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 370</span></td>
                                </tr>
                                <tr>
                                    <th className="style02 table-coner-round-bl" style={{fontSize: '13px'}}>GARE
                                        MONTPARNASSE
                                    </th>
                                    <th className="style02" align="left" style={{fontSize: '13px'}}>ORLY</th>
                                    <td className="style03" align="center">
                                        <span>€ 120</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 130</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 140</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 150</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 160</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 170</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 240</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 250</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 260</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 270</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 280</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 290</span></td>
                                    <td className="style03" align="center">
                                        <span>€ 300</span></td>
                                    <td className="style03 table-coner-round-br" align="center">
                                        <span>€ 310</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <h2 className="excursion-title" style={{marginTop: '40px', textAlign: 'center'}}>EXCURSION TRIP
                        RATES</h2>
                    <div className="table-container-outer">
                        <div className="table-container">
                            <table style={{marginTop: '40px', width: '100%'}}>
                                <tbody>
                                <tr>
                                    <th className="row-title" colSpan={8}>PARIS EXCURSION TRIPS</th>
                                </tr>
                                <tr style={{height: '40px'}}>
                                    <th className="style02 align-center table-coner-round-tl" colSpan={2}>TRIP PLACE
                                    </th>
                                    <th colSpan={3} className="style02 align-center">1 to 4 Passengers</th>
                                    <th colSpan={3} className="style02 align-center table-coner-round-tr">1 to 8
                                        Passengers
                                    </th>
                                </tr>
                                <tr>
                                    <th className="style02" colSpan={2} align="left"
                                        style={{fontSize: '13px'}}>PARIS DAY
                                        TRIP (4 Hours)
                                    </th>
                                    <td colSpan={3} className="style03 align-center">
                                        <span>€ 260</span></td>
                                    <td colSpan={3} className="style03 align-center">
                                        <span>€ 280</span></td>
                                </tr>
                                <tr>
                                    <th className="style02" colSpan={2} align="left"
                                        style={{fontSize: '13px'}}>PARIS DAY
                                        TRIP (8 Hours)
                                    </th>
                                    <td colSpan={3} className="style03 align-center">
                                        <span>€ 520</span></td>
                                    <td colSpan={3} className="style03 align-center">
                                        <span>€ 630</span></td>
                                </tr>
                                <tr>
                                    <th className="style02 table-coner-round-bl" colSpan={2} align="left"
                                        style={{fontSize: '13px'}}>PARIS NIGHT
                                        TRIP (4 Hours)
                                    </th>
                                    <td colSpan={3} className="style03 align-center">
                                        <span>€ 300</span></td>
                                    <td colSpan={3} className="style03 align-center table-coner-round-br">
                                        <span>€ 320</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
